import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby"
import htmlSerializer from "../../utils/htmlSerializer"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const GreenBubbleSectionSlice = ({ slice }) => (
  <div
    className={`${
      slice.primary.green_bubble_full_width_background
        ? "bg-white"
        : "bg-site-green-light"
    } pt-16 md:pt-32 pb-12 md:pb-24`}
  >
    <div className="relative flex flex-col items-center">
      {slice.primary.green_bubble_title ? (
        <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
          <h2 className="text-white text-base md:text-2xl uppercase font-bold text-center">
            {slice.primary.green_bubble_title}
          </h2>
        </div>
      ) : null}
      <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg overflow-hidden z-10">
        <div
          className={`green-bubble-slice-section ${
            slice.primary.two_column_bullet_list
              ? "list-two-col"
              : "list-one-col"
          } w-full max-w-2xl flex flex-col justify-center mt-12 mb-8 z-10`}
        >
          <RichText
            render={slice.primary.green_bubble_content.richText}
            htmlSerializer={htmlSerializer}
          />
          {slice.items &&
            slice.items.map((item, index) => {
              if (item.feature_title !== null) {
                const featureImage = getImage(item.feature_image)
                return (
                  <div
                    key={`feature${index}`}
                    className="flex flex-row px-4 md:px-10 py-4 md:py-7 md:space-x-8"
                  >
                    {item?.feature_image?.gatsbyImageData && (
                      <div className="w-1/3 p-2 hidden md:flex">
                        <div className="grid bg-site-red p-4 md:p-10 rounded-full">
                          <GatsbyImage
                            style={{
                              gridArea: "1/1",
                              objectFit: "cover",
                            }}
                            image={featureImage}
                          />
                        </div>
                      </div>
                    )}
                    <div className="w-full md:w-2/3 flex flex-col justify-center">
                      <h3 className="py-2 md:pt-4 md:pb-6 font-bold text-xl">
                        {item.feature_title}
                      </h3>
                      <p>{item.feature_details}</p>
                    </div>
                  </div>
                )
              }
              return null; // Add a return statement for the "if" condition
            })}

          {slice.primary.green_bubble_buttons ? (
            <div className="flex flex-col md:flex-row items-center justify-center mt-12 md:space-x-6 space-y-6 md:space-y-0">
              {slice.primary.green_bubble_button_name_1 ? (
                slice.primary.green_bubble_button_link_1.link_type ===
                  "Document" ||
                slice.primary.green_bubble_button_link_1.link_type === "Any" ? (
                  <Link
                    to={
                      slice.primary.green_bubble_button_link_1.uid
                        ? `/${slice.primary.green_bubble_button_link_1.uid}`
                        : "/"
                    }
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-6 py-3">
                      {slice.primary.green_bubble_button_name_1
                        ? slice.primary.green_bubble_button_name_1
                        : "Button Name"}
                    </button>
                  </Link>
                ) : slice.primary.green_bubble_button_link_1.link_type ===
                    "Web" ||
                  slice.primary.green_bubble_button_link_1.link_type ===
                    "Media" ? (
                  <a
                    href={slice.primary.green_bubble_button_link_1.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-6 py-3">
                      {slice.primary.green_bubble_button_name_1
                        ? slice.primary.green_bubble_button_name_1
                        : "Button Name"}
                    </button>
                  </a>
                ) : null
              ) : null}

              {slice.primary.green_bubble_button_name_2 ? (
                slice.primary.green_bubble_button_link_2.link_type ===
                  "Document" ||
                slice.primary.green_bubble_button_link_2.link_type === "Any" ? (
                  <Link
                    to={
                      slice.primary.green_bubble_button_link_2.uid
                        ? `/${slice.primary.green_bubble_button_link_2.uid}`
                        : "/"
                    }
                  >
                    <button className="w-full bg-white text-base md:text-lg text-site-red border border-site-red hover:bg-site-red hover:text-white uppercase tracking-wider px-6 py-3">
                      {slice.primary.green_bubble_button_name_2
                        ? slice.primary.green_bubble_button_name_2
                        : "Button Name"}
                    </button>
                  </Link>
                ) : slice.primary.green_bubble_button_link_2.link_type ===
                    "Web" ||
                  slice.primary.green_bubble_button_link_2.link_type ===
                    "Media" ? (
                  <a
                    href={slice.primary.green_bubble_button_link_2.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full bg-white text-base md:text-lg text-site-red border border-site-red hover:bg-site-red hover:text-white uppercase tracking-wider px-6 py-3">
                      {slice.primary.green_bubble_button_name_2
                        ? slice.primary.green_bubble_button_name_2
                        : "Button Name"}
                    </button>
                  </a>
                ) : null
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="absolute bottom-0 -mb-2 opacity-20 z-0">
          <StaticImage
            src="../../images/backgrounds/sra-mtn.png"
            width={600}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="background image"
          />
        </div>
      </div>
    </div>
  </div>
)

export default GreenBubbleSectionSlice
